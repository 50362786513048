import * as Vue from "vue";
import { App } from "vue";
import * as lib from "table";
import { PageService } from "table";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import { AuthenticationService } from "@/services/AuthenticationService";
import TablesAndActions from "@/components/smart/TablesAndActions/TablesAndActions.vue";
import { PageFrontendApi } from "@/modules/page/types";

function install(Vue: Vue.App<Element>): App<Element> {
  Vue.component(ActionButton.name, ActionButton);
  Vue.component(ActionsButtons.name, ActionsButtons);
  Vue.component(TablesAndActions.name, TablesAndActions);
  return Vue;
}

const frontend = {
  ApiActionsService,
  AuthenticationService,
};

const api = {
  frontend,
  method_exec: ApiActionsService.methodExec.bind(ApiActionsService),
  action_list: ApiActionsService.actionList.bind(ApiActionsService),
  action_exec: ApiActionsService.actionExec.bind(ApiActionsService),
  action_progress: ApiActionsService.actionProgress.bind(ApiActionsService),
  action_cancel: ApiActionsService.actionCancel.bind(ApiActionsService),
  action_result: ApiActionsService.actionResult.bind(ApiActionsService),
  process_reply: ApiActionsService.processReply.bind(ApiActionsService),
  lib,
} as PageFrontendApi;

PageService.setExternalOptions({
  api,
  install,
});
