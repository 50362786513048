import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "dynamic-menu-block__header" }
const _hoisted_2 = ["data-is-open-header"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_AppRouterLink = _resolveComponent("AppRouterLink")!
  const _component_DynamicMainMenuItem = _resolveComponent("DynamicMainMenuItem", true)!

  return (_ctx.item.hr)
    ? (_openBlock(), _createElementBlock("hr", _normalizeProps(_mergeProps({ key: 0 }, _ctx.item.props)), null, 16))
    : (_ctx.item.description)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 1,
          class: "label-s16-c4f4-lh19 dynamic-main-menu-header-description"
        }, _ctx.item.props), _toDisplayString(_ctx.item.description), 17))
      : (_ctx.item.url && !_ctx.isHeader)
        ? (_openBlock(), _createBlock(_component_AppRouterLink, _mergeProps({
            key: 2,
            mark: _ctx.headerItem,
            to: _ctx.item.url,
            class: "dynamic-menu-pre-button-link"
          }, _ctx.item.props, { onIsActiveChanged: _ctx.onIsActiveChanged }), {
            default: _withCtx(() => [
              _createVNode(_component_ButtonComponent, _mergeProps({ class: "button-opacity dynamic-menu-button" }, _ctx.item.props), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.caption), 1)
                ]),
                _: 1
              }, 16)
            ]),
            _: 1
          }, 16, ["mark", "to", "onIsActiveChanged"]))
        : (_ctx.item.component)
          ? (_openBlock(), _createBlock(_component_ButtonComponent, _mergeProps({
              key: 3,
              class: "button-opacity dynamic-menu-button"
            }, _ctx.item.props, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickComponent(_ctx.item.component)))
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.caption || _ctx.getCaptionComponent(_ctx.item.component)), 1)
              ]),
              _: 1
            }, 16))
          : (_ctx.item.modal)
            ? (_openBlock(), _createBlock(_component_ButtonComponent, _mergeProps({
                key: 4,
                class: "button-opacity dynamic-menu-button"
              }, _ctx.item.props, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickModal(_ctx.item)))
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.caption || _ctx.item.modal), 1)
                ]),
                _: 1
              }, 16))
            : (_ctx.item.caption)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  class: _normalizeClass({ 'dynamic-menu-block': _ctx.isHeader })
                }, [
                  _createElementVNode("div", _hoisted_1, [
                    (!_ctx.item.url)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isHeader ? 'ButtonComponent' : 'div'), {
                          key: 0,
                          class: _normalizeClass({
          'button-opacity app-h-s22-h24-w7-c333 dynamic-main-menu-header-button':
            _ctx.isHeader,
          'app-h-s18-h24-w7-c333': !_ctx.isHeader,
        }),
                          icon: _ctx.isHeader ? _ctx.TABLE_ICONS.chevronDown : undefined,
                          iconRotate180: _ctx.getOpen(_ctx.item),
                          isIconRight: !_ctx.isOpenHeader,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onToggleMenu(_ctx.item)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(_ctx.item.caption), 1)
                          ]),
                          _: 1
                        }, 8, ["class", "icon", "iconRotate180", "isIconRight"]))
                      : (_ctx.isMainMenuMounted)
                        ? (_openBlock(), _createBlock(_component_AppRouterLink, {
                            key: 1,
                            mark: _ctx.headerItem,
                            to: _ctx.item.url,
                            class: "app-h-s22-h24-w7-c333 dynamic-main-menu-header-button",
                            onIsActiveChanged: _ctx.onIsActiveChanged
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.item.caption), 1)
                            ]),
                            _: 1
                          }, 8, ["mark", "to", "onIsActiveChanged"]))
                        : _createCommentVNode("", true)
                  ]),
                  _withDirectives(_createElementVNode("div", {
                    "data-is-open-header": _ctx.boolToString(_ctx.isOpenHeader),
                    class: "dynamic-menu-body"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (item2, i) => {
                      return (_openBlock(), _createBlock(_component_DynamicMainMenuItem, {
                        key: item2.caption ?? i,
                        "get-open": _ctx.getOpen,
                        "header-item": _ctx.headerItem ?? _ctx.isHeader ? _ctx.item : undefined,
                        "is-main-menu-mounted": _ctx.isMainMenuMounted,
                        "is-open-header": _ctx.isOpenHeader,
                        item: item2,
                        "onSwitch:open": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onToggleMenu($event))),
                        "onClick:component": _cache[4] || (_cache[4] = ($event: any) => (_ctx.onClickComponent($event))),
                        onIsActiveChanged: _ctx.onIsActiveChanged
                      }, null, 8, ["get-open", "header-item", "is-main-menu-mounted", "is-open-header", "item", "onIsActiveChanged"]))
                    }), 128))
                  ], 8, _hoisted_2), [
                    [_vShow, _ctx.item.items && _ctx.getOpen(_ctx.item)]
                  ])
                ], 2))
              : _createCommentVNode("", true)
}