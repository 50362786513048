import { ApiService, Dictionary, HttpService } from "table";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { FormReadDto } from "table/dist/services/Api/types/FormReadDto";

class ApiVocService {
  getQueryParams = ApiService.getQueryParams;

  vocsOrganizationsListVersions(record_id: any) {
    return HttpService.get<ApiListWrapper<VocValueDto>>(
      "/api/vocs/organizations?" +
        ApiService.getQueryParams({
          action: "list_versions",
          record_id,
        }),
    );
  }

  vocsListVersionsByValueId(value_id: any) {
    return HttpService.get<ApiListWrapper<VocValueDto>>(
      "/api/vocs?" +
        ApiService.getQueryParams({
          action: "list_by_value_id",
          value_id,
        }),
    );
  }

  postForm(action: string, data: any, getParams: Dictionary = {}) {
    return HttpService.post(
      "/api/vocs?" +
        this.getQueryParams({
          action,
          ...getParams,
        }),
      {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  delete(record_id: string | number, voc_type: string) {
    return HttpService.post<undefined>(
      `/api/vocs?action=delete&record_id=${record_id}&voc_type=${voc_type}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: "{}",
      },
    );
  }

  archive(record_id: string | number, voc_type: string) {
    return HttpService.post<VocValueDto>(
      `/api/vocs?action=to_archive&record_id=${record_id}&voc_type=${voc_type}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: "{}",
      },
    );
  }

  toMakeActive(record_id: string | number, voc_type: string) {
    return HttpService.post(
      ApiService.getUrl(`/api/vocs?`, {
        action: "to_make_active",
        record_id,
        voc_type,
      }),
    );
  }

  changeHint(
    queryParams: { voc_type: string; attr: string },
    body: { text: string },
  ) {
    return HttpService.post<FormReadDto>(
      `/api/vocs?` +
        this.getQueryParams({
          action: "change_hint",
          ...queryParams,
        }),
      {
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }
}

export default new ApiVocService();
