
import { defineComponent, PropType } from "vue";
import { DynamicMainMenuItemDto } from "@/services/AppApiService/types";
import {
  boolToString,
  ButtonComponent,
  TABLE_ICONS,
  TDynamicModalService,
} from "table";
import AppRouterLink from "@/components/AppRouterLink.vue";
import { CALC_COMPONENTS_DICT } from "@/common/consts";
import AppNavLink from "@/components/AppNavLink.vue";

export default defineComponent({
  name: "DynamicMainMenuItem",
  components: {
    AppRouterLink,
    ButtonComponent,
  },
  props: {
    item: {
      type: Object as PropType<DynamicMainMenuItemDto>,
      required: true,
    },
    headerItem: {
      type: Object as PropType<DynamicMainMenuItemDto>,
      required: false,
    },
    getOpen: {
      type: Function as PropType<(item: DynamicMainMenuItemDto) => boolean>,
      required: true,
    },
    isHeader: Boolean,
    isOpenHeader: {
      type: Boolean,
      default: false,
    },
    isMainMenuMounted: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["switch:open", "click:component", "is-active-changed"],
  setup() {
    return {
      TABLE_ICONS,
      boolToString,
    };
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    onToggleMenu(item: DynamicMainMenuItemDto) {
      this.$emit("switch:open", item);
    },

    onClickComponent(component: string) {
      this.$emit("click:component", component);
    },

    onClickModal(item: DynamicMainMenuItemDto) {
      if (!item.modal) {
        return;
      }

      TDynamicModalService.show(item.modal, item.context);
    },

    getCaptionComponent(component: string) {
      return CALC_COMPONENTS_DICT[component]?.title;
    },

    onIsActiveChanged(appNavLink: InstanceType<typeof AppNavLink>) {
      this.$emit("is-active-changed", appNavLink);
    },
  },
});
