import { PanelFiltersSave } from "table/dist/components/PanelFilters/types";
import { UserSettingsService } from "@/services/UserSettingsService/UserSettingsService";
import { USER_SETTINGS_KEYS } from "@/services/UserSettingsService/consts";

export const filterPresetsSettings = {
  savePresets(id: string, value: PanelFiltersSave[] | undefined) {
    return UserSettingsService.set(filterPresetsSettings.getPresetKey(id), value);
  },
  async addPreset(id: string, value: PanelFiltersSave) {
    const currentPresets = (await this.getPresets(id)) || [];
    currentPresets.push(value);
    await filterPresetsSettings.savePresets(id, currentPresets);
    return currentPresets;
  },
  async deletePreset(id: string, value: PanelFiltersSave) {
    let currentPresets = (await this.getPresets(id));
    if (!currentPresets) {
      return;
    }

    currentPresets = currentPresets.filter(x => x.caption !== value.caption);
    await filterPresetsSettings.savePresets(id, currentPresets);
    return currentPresets;
  },
  getPresets(id: string) {
    return UserSettingsService.get<PanelFiltersSave[]>(
      filterPresetsSettings.getPresetKey(id),
    );
  },
  getPresetKey(id: string) {
    return USER_SETTINGS_KEYS.PRESETS + "_" + id;
  },
};
