import { NotificationsService, PanelFiltersSaveModuleAbstract } from "table";
import { PanelFiltersSave } from "table/dist/components/PanelFilters/types";
import { filterPresetsSettings } from "@/services/UserSettingsService/filterPresetsSettings";

export class PanelFiltersSaveModule extends PanelFiltersSaveModuleAbstract {
  constructor(public id: string) {
    super();
  }

  async init() {
    this.filters = (await filterPresetsSettings.getPresets(this.id)) || [];
    return this;
  }

  async deleteFilter(filter: PanelFiltersSave) {
    this.filters =
      (await filterPresetsSettings.deletePreset(this.id, filter)) || [];
    NotificationsService.send({
      title: "Удаление",
      text: `Фильтр "${filter.caption}" удалён`,
      duration: 3000,
      type: "success",
    });
  }

  async saveFilter(filter: PanelFiltersSave) {
    this.filters = await filterPresetsSettings.addPreset(this.id, filter);
    NotificationsService.send({
      title: "Сохранение",
      text: `Фильтр "${filter.caption}" сохранён`,
      duration: 3000,
      type: "success",
    });
  }
}
