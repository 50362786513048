
import { defineComponent, inject, PropType } from "vue";
import {
  ApiHelper,
  ButtonComponent,
  CircleLoader,
  Subject,
  TDynamicModalData,
} from "table";
import {
  ActionReportForms,
  ActionsProgress,
} from "@/services/ApiActions/types";
import { IApiDefaultPageAndPageFrontendApi } from "@/modules/page/types";

export interface ProgressDynamicModalContext {
  progressSubject: Subject<ActionsProgress | undefined>;
  onCancelProgress: () => Promise<any>;
  action?: Partial<ActionReportForms>;
}

export default defineComponent({
  name: "ProgressDynamicModal",
  components: {
    ButtonComponent,
    CircleLoader,
  },
  props: {
    context: {
      type: Object as PropType<ProgressDynamicModalContext>,
      required: true,
    },
    form: {
      type: Object as PropType<TDynamicModalData["formProp"]>,
      required: true,
    },
  },
  setup() {
    const api = inject<IApiDefaultPageAndPageFrontendApi>("api")!;
    return { api };
  },
  data() {
    return {
      progress: undefined as ActionsProgress | undefined,
    };
  },
  computed: {
    progressPercent(): number {
      return this.progress?.progress ?? 0;
    },
  },
  watch: {},
  created() {},
  async mounted() {
    if (this.context.action?.caption) {
      this.form.set_caption(this.context.action.caption);
    }

    this.context.progressSubject.subscribe((progress) => {
      this.progress = progress;
      if (!progress) {
        this.api.modal.close(this);
      }
    });
  },
  methods: {
    async onCancelProgress() {
      await ApiHelper.wrapNotifyError(
        async () => {
          await this.context.onCancelProgress();
          this.api.modal.close(this);
        },
        {
          title: "Отмена " + (this.context.action?.caption ?? ""),
        },
      );
    },
  },
});
