
import { ComponentPropsOptions, ComponentPublicInstance, defineComponent, PropType } from "vue";
import { ActionReportForms } from "@/services/ApiActions/types";
import {
  ButtonComponent,
  ButtonIconComponent,
  TABLE_ICONS,
  TooltipComponent,
} from "table";

export default defineComponent({
  name: "ActionButton",
  components: {
    ButtonComponent,
    TooltipComponent,
    ButtonIconComponent,
  },
  props: {
    class: {},
    childClass: {},
    style: {},
    data: {
      type: [Object, String] as PropType<ActionReportForms | "delimiter">,
      required: true,
    },
  },
  emits: ["click"],
  setup() {
    return {
      TABLE_ICONS,
      Boolean,
    };
  },
  data() {
    return {
      open: {
        tooltip: false,
      },
    };
  },
  computed: {
    isDelimiter() {
      return this.data === "delimiter";
    },
  },
  watch: {},
  created() {},
  methods: {
    tooltipComponent(): InstanceType<typeof TooltipComponent> {
      return this.$refs.tooltipRef as any;
    },

    onClick(event: MouseEvent, data: ActionReportForms) {
      if (data.actions) {
        this.open.tooltip = true;
        this.tooltipComponent().show(event, {
          target: (this.$refs.buttonComponentRef as ComponentPublicInstance).$el,
        });
        return;
      }

      this.$emit("click", {
        data,
        event,
      });
    },

    onHide() {
      this.open.tooltip = false;
    },
  },
});
