
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNavLink",
  components: {},
  props: {
    isActive: Boolean,
    isExactActive: Boolean,
    class: {},
    href: {},
    navigate: {},
    mark: {}, // пользовательская метка
    route: {},
    target: String,
    style: {},
  },
  emits: ["is-active-changed"],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {},
  watch: {
    isActive() {
      this.onIsActiveChanged();
    },
  },
  created() {},
  mounted() {
    if (this.isActive) {
      this.onIsActiveChanged();
    }
  },
  methods: {
    onIsActiveChanged() {
      this.$emit("is-active-changed", this);
    },
  },
});
