import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataArray, (action) => {
    return (_openBlock(), _createBlock(_component_ActionButton, {
      key: action.url + action.caption + action.action_name,
      data: action,
      "child-class": "button-blue",
      class: "form-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick($event)))
    }, null, 8, ["data"]))
  }), 128))
}