
import { defineComponent } from "vue";
import "table/dist/style.css";
import HeaderComponent from "@/components/HeaderComponent.vue";
import {
  ContextMenuGlobal,
  NotificationsComponent,
  TDynamicModal,
} from "table";
import "@/styles/labels.scss";
import "@/styles/blocks.scss";
import "@/styles/voc.scss";
import "@/styles/report-form.scss";
import "@/styles/table.scss";
import { AuthenticationService } from "@/services/AuthenticationService";

export default defineComponent({
  name: "App",
  components: {
    TDynamicModal,
    HeaderComponent,
    NotificationsComponent,
    ContextMenuGlobal,
  },
  data() {
    return {};
  },
  created() {
    AuthenticationService.startInterval();
  },
  beforeUnmount() {
    AuthenticationService.stopInterval();
  },
});
