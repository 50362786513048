import { USER_SETTINGS_KEYS } from "@/services/UserSettingsService/consts";
import {
  ApiHelper,
  ApiService,
  debounce,
  Dictionary,
  HttpService,
  Logger,
  Subject,
} from "table";
import { AuthenticationService } from "@/services/AuthenticationService";
import { VocValueDto } from "table/dist/services/Api/types/VocRepositoryDto";
import { initUserSettings } from "@/services/UserSettingsService/helpers";

const LS_START_KEY = "USER_SETTINGS_";

type USKeyType = keyof typeof USER_SETTINGS_KEYS | string;

// todo этот сервис будет обращаться к серверу для получения и сохранения настроек юзера.
export class UserSettingsService {
  static queueGetRequests: Dictionary<Subject<any>> = {};
  static queueSetRequests: Dictionary<{ subject: Subject<void>; value: any }> =
    {};

  static settings: Dictionary<{ value: any } | undefined> = {};
  private static executeQueueGetRequests = debounce(async () => {
    const queueGetRequests = this.queueGetRequests;
    this.queueGetRequests = {};
    const setting_key = Object.keys(queueGetRequests);

    let result: { response: Response; json: Dictionary<any> } | undefined;
    try {
      if (AuthenticationService.isAuth.value) {
        result = await HttpService.post<Dictionary>(
          ApiService.getUrl("/api/user_settings?", {
            action: "read",
          }),
          {
            body: JSON.stringify(setting_key),
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
      }
    } catch (ex) {
      await ApiHelper.wrapNotifyError(ex, {
        isError: true,
        title: "Ошибка при получении пользовательских настроек",
        duration: 5000,
      });
    }
    setting_key.forEach((key) => {
      this.settings[key] = {
        value: result?.json ? result.json[key] : undefined,
      };
      const subject = queueGetRequests[key];
      const setting = this.settings[key];
      subject.next(setting?.value);
    });
  }, 0);

  private static executeQueueSetRequests = debounce(async () => {
    const queueSetRequests = this.queueSetRequests;
    this.queueSetRequests = {};
    const setting_key = Object.keys(queueSetRequests);
    const body = setting_key.reduce((body, settingKey) => {
      body[settingKey] = queueSetRequests[settingKey].value;
      return body;
    }, {} as Dictionary);
    await HttpService.post<VocValueDto>(
      ApiService.getUrl("/api/user_settings?", {
        action: "update",
        partial_update_attrs: true,
      }),
      {
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    setting_key.forEach((key) => {
      const queue = queueSetRequests[key];
      if (!queue) {
        Logger.error(`User setting "${key}" not queue object after saving.`);
        return;
      }

      queue.subject.next();
    });
  }, 0);

  static async set(key: USKeyType, value: any) {
    const fullKey = this.getFullKey(key);
    this.settings[fullKey] = { value };

    const subject = new Subject<void>();
    // ставим запрос в очередь
    this.queueSetRequests[fullKey] = {
      subject,
      value,
    };
    // запускаем очередь
    this.executeQueueSetRequests();
    // подписываемся на получение результата из очереди
    return new Promise<void>((resolve) => {
      subject.subscribe(() => {
        resolve();
      });
    });
  }

  static async get<T>(key: USKeyType, update = false): Promise<T | undefined> {
    const fullKey = this.getFullKey(key);
    const currentSetting = this.settings[key];
    if (currentSetting && !update) {
      return currentSetting.value;
    }

    const subject = new Subject<any>();
    // ставим запрос в очередь
    this.queueGetRequests[fullKey] = subject;
    // запускаем очередь
    this.executeQueueGetRequests();
    // подписываемся на получение результата из очереди
    return new Promise((resolve) => {
      subject.subscribe((data) => {
        if (typeof data === "string" && data.length > 1) {
          const parseData = JSON.parse(data);
          // data === {} когда значение value undefined
          if ("value" in parseData || data === "{}") {
            resolve(parseData.value as T);
            return;
          }
        }

        resolve(data === null ? undefined : (data as any as T));
      });
    });
  }

  static async updateCurrentLoadedValues() {
    await Promise.all(
      Object.keys(this.settings).map((key) => this.get(key, true)),
    );
    await initUserSettings();
  }

  private static getFullKey(key: string) {
    return LS_START_KEY + key;
  }
}
