
import { defineComponent } from "vue";
import {
  ApiHelper,
  ApiService,
  boolToString,
  Dictionary,
  Logger,
  PanelFilters,
  PanelFiltersSet,
  ScreenSpinner,
  TableActionsComponent,
  TableComponent,
  TableDataSet,
  TableDataSetFactory,
  TableLocalRepository,
  TableMultiHeaders,
  TableRow,
} from "table";
import {
  TablesAndActionsContextOnlyTable,
  TablesAndActionsDto,
} from "@/components/smart/TablesAndActions/types";
import { ActionReportForms } from "@/services/ApiActions/types";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import tablesAndActionsTestJson from "./tables-and-actions.json";
import { toNormaliseDomId } from "@/common/helpers/toNormaliseDomId";
import { PanelFiltersSaveModule } from "@/classes/PanelFiltersSaveModule";
import { applyPanelFiltersData } from "@/common/helpers/applyPanelFiltersData";
import { useSaveSettingsInTableDataSet } from "@/services/UserSettingsService/helpers";
import { TableRowObj } from "table/dist/types/TableRowObj";

interface TablesAndActionsTableState {
  tableDataSet: TableDataSet;
  factory: TableDataSetFactory;
}

interface TablesAndActionsTableComputed {
  table: TablesAndActionsTableState;
  name: string;
  key: string;
}

export default defineComponent({
  name: "TablesAndActions",
  components: {
    ScreenSpinner,
    ActionsButtons,
    TableComponent,
    TableMultiHeaders,
    TableActionsComponent,
    PanelFilters,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  emits: [],
  setup() {
    return {
      boolToString,
    };
  },
  data() {
    return {
      tablesAndActionsDto: null as TablesAndActionsDto | null,
      tables: {} as Dictionary<TablesAndActionsTableState>,
      loading: true,
      tab: null as string | null,
    };
  },
  computed: {
    tablesComputed(): TablesAndActionsTableComputed[] {
      if (!this.tablesAndActionsDto || this.loading) {
        return [];
      }

      const keys = Object.keys(this.tablesAndActionsDto.repository.tables);
      return keys.map((key) => this.getTableComputed(key));
    },
    currentTable(): TablesAndActionsTableComputed | undefined {
      if (!this.tab || !this.tables[this.tab] || this.loading) {
        return undefined;
      }
      return this.getTableComputed(this.tab);
    },
    selectedValues(): TableRowObj[] {
      if (!this.currentTable) {
        return [];
      }

      return this.currentTable.table.tableDataSet.rows.filter(
        (x) => x.select,
      ) as TableRowObj[];
    },
  },
  watch: {},
  async created() {
    await this.init();
  },
  methods: {
    onSwitchRow(currentTable: TablesAndActionsTableComputed, row: number) {
      const tableRow = currentTable.table.tableDataSet.getRow(row);
      if (!tableRow) {
        return;
      }

      tableRow.select = !tableRow.select;
    },

    onSelectedAll(
      currentTable: TablesAndActionsTableComputed,
      mode: "" | "clear" | "all" = "",
    ) {
      const isSelectedAll =
        this.selectedValues.length ===
        currentTable.table.tableDataSet.rows.length;
      if (mode === "clear" || isSelectedAll) {
        currentTable.table.tableDataSet.rows.forEach((x) => (x.select = false));
      } else {
        currentTable.table.tableDataSet.rows.forEach((tableRow) => {
          tableRow.select = true;
        });
      }
    },

    async init() {
      this.loading = true;
      await ApiHelper.wrapNotifyError(
        async () => {
          const tablesAndActionsDto = await this.getTablesAndActionsDto();
          const tableKeys = Object.keys(tablesAndActionsDto.repository.tables);
          const types = tablesAndActionsDto.repository.types;
          for (let tableKey of tableKeys) {
            const data = tablesAndActionsDto.data[tableKey];
            const { model, enable_col_num, headers, tab_name } =
              tablesAndActionsDto.repository.tables[tableKey];
            const uniqueId = toNormaliseDomId("tables-and-actions-" + this.url);
            const filtersSaveModule = new PanelFiltersSaveModule(uniqueId);
            await filtersSaveModule.init();
            const filtersSet = new PanelFiltersSet(filtersSaveModule);
            filtersSet.subject.subscribe((data) =>
              applyPanelFiltersData(
                this.tables[tableKey].tableDataSet as TableDataSet,
                data,
              ),
            );
            const factory = new TableDataSetFactory({
              rows: data,
              rowsOriginal: data,
              edit: { edit: false },
              types: types || {},
              headers: headers || [],
              tableName: tableKey,
              modelDtoArray: model,
              uniqId: uniqueId,
              repository: new TableLocalRepository(),
              tableDataSetOptions: {
                filtersSet,
              },
            });

            const tableState: TablesAndActionsTableState = {
              tableDataSet: await factory.create(),
              factory,
            };
            this.tables[tableKey] = tableState;
            await useSaveSettingsInTableDataSet(
              this.tables[tableKey].tableDataSet,
            );
          }

          this.tablesAndActionsDto = tablesAndActionsDto;
          this.tab = tableKeys[0];
        },
        {
          title: "Загрузка таблицы",
          reject: (e) => {
            Logger.error(e);
          },
        },
      );
      this.loading = false;
    },

    async onClickFormAction(formAction: ActionReportForms) {
      let context: TablesAndActionsContextOnlyTable | undefined = undefined;
      const currentTable = this.currentTable;
      if (currentTable) {
        const tableDataSet = currentTable.table.tableDataSet;
        const selectedRowIndex: number | undefined =
          tableDataSet.selected.cursor?.row;
        const tableContext = tableDataSet.getContext();
        const selectedTableRow = tableDataSet.getRow(selectedRowIndex);
        const selected_row = selectedTableRow
          ? TableRow.getValue(selectedTableRow)
          : undefined;
        const selected_rows = tableContext.selected_rows
          .map((x) => {
            const tableRow = tableDataSet.getRow(x);
            return tableRow ? TableRow.getValue(tableRow) : undefined;
          })
          .filter(Boolean);
        const onlyTableContext: TablesAndActionsContextOnlyTable = {
          selected_row,
          selected_rows,
        };
        context = onlyTableContext;
      }

      try {
        const execResult = await ApiActionsService.executeExec(
          formAction,
          context,
          true,
        );

        const refresh = execResult.result?.refresh;
        if (refresh === "all") {
          await this.init();
        }
      } catch (e: any) {
        await ApiHelper.wrapNotifyError(e, { isError: true });
      }
    },

    async getTablesAndActionsDto(): Promise<TablesAndActionsDto> {
      if (!this.url) {
        return tablesAndActionsTestJson as any;
      }

      return (await ApiService.request<TablesAndActionsDto>(this.url)).json;
    },

    getTableComputed(key: string): TablesAndActionsTableComputed {
      return {
        table: this.tables[key] as TablesAndActionsTableState,
        name: this.tablesAndActionsDto!.repository.tables[key].tab_name,
        key,
      };
    },
  },
});
