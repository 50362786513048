import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Teleport as _Teleport, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "default-page-container table-scroll-container" }
const _hoisted_2 = {
  key: 0,
  class: "default-page-padding-inline"
}
const _hoisted_3 = {
  key: 0,
  class: "app-h-s22-h24-w7-c333"
}
const _hoisted_4 = { class: "default-page-padding-inline" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "padding-bottom-10 flex-row-between-grow" }
const _hoisted_7 = {
  key: 0,
  class: "flex-row-end-g10"
}
const _hoisted_8 = { class: "tabs-container" }
const _hoisted_9 = ["data-active", "onClick"]
const _hoisted_10 = { class: "flex-column-overflow-grow" }
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableActionsComponent = _resolveComponent("TableActionsComponent")!
  const _component_ActionsButtons = _resolveComponent("ActionsButtons")!
  const _component_PanelFilters = _resolveComponent("PanelFilters")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ScreenSpinner = _resolveComponent("ScreenSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tablesAndActionsDto?.form)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.tablesAndActionsDto.form.caption)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.tablesAndActionsDto.form.caption), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.tablesComputed.length > 0 && _ctx.tablesAndActionsDto)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.currentTable?.table.tableDataSet.isTableMounted)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(), _createBlock(_Teleport, {
                    disabled: !_ctx.currentTable.table.tableDataSet.isFullScreenAndMounted,
                    to: '#' + _ctx.currentTable.table.tableDataSet.idContainerDivUp
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_TableActionsComponent, {
                        "table-data-set": _ctx.currentTable.table.tableDataSet,
                        "changes-state": "hide",
                        "filter-state": "enable",
                        "row-append-state": "hide"
                      }, null, 8, ["table-data-set"]),
                      (_ctx.tablesAndActionsDto.repository.actions?.length > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_ActionsButtons, {
                              "data-array": _ctx.tablesAndActionsDto.repository.actions,
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickFormAction($event.data)))
                            }, null, 8, ["data-array"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.currentTable.table.tableDataSet.options.filtersSet.open)
                      ? (_openBlock(), _createBlock(_component_PanelFilters, {
                          key: 0,
                          "data-set": _ctx.currentTable.table.tableDataSet.options.filtersSet,
                          "model-array": _ctx.currentTable.table.tableDataSet.model,
                          "table-data-set": _ctx.currentTable.table.tableDataSet,
                          types: _ctx.currentTable.table.tableDataSet.types,
                          style: {"margin-bottom":"10px"}
                        }, null, 8, ["data-set", "model-array", "table-data-set", "types"]))
                      : _createCommentVNode("", true)
                  ], 8, ["disabled", "to"]))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tablesComputed, (value) => {
                return (_openBlock(), _createElementBlock("h3", {
                  key: value.key,
                  "data-active": _ctx.boolToString(_ctx.tab === value.key),
                  class: "tabs-container__tab app-h-s22-h24-w7-c333",
                  onClick: ($event: any) => (_ctx.tab = value.key)
                }, _toDisplayString(value.name), 9, _hoisted_9))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_TableComponent, {
              isShowInfo: false,
              "table-data-set": _ctx.currentTable.table.tableDataSet,
              "selection-mode": "multiple",
              "onUpdate:cells": _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentTable.table.tableDataSet.updateCells($event))),
              onRowAppend: _cache[3] || (_cache[3] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowAppend())),
              onRowInsert: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowInsert($event))),
              onRowDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowDelete($event))),
              onRowDuplicate: _cache[6] || (_cache[6] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowDuplicate($event))),
              onCellsClear: _cache[7] || (_cache[7] = ($event: any) => (_ctx.currentTable.table.tableDataSet.cellsClear($event))),
              onRowDown: _cache[8] || (_cache[8] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowMoveDown($event))),
              onRowUp: _cache[9] || (_cache[9] = ($event: any) => (_ctx.currentTable.table.tableDataSet.rowMoveUp($event))),
              onClickColumnSelect: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onSwitchRow(_ctx.currentTable, $event)))
            }, {
              headers: _withCtx(({ classes, tableDataSet }) => [
                _createVNode(_component_TableMultiHeaders, {
                  classes: classes,
                  headers: _ctx.currentTable.table.factory.options.headers,
                  model: _ctx.currentTable.table.factory.modelDtoSort,
                  "rows-selected": _ctx.selectedValues.length,
                  "table-data-set": tableDataSet,
                  "left-col": "checkbox",
                  onClickLeftCol: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelectedAll(_ctx.currentTable, $event)))
                }, null, 8, ["classes", "headers", "model", "rows-selected", "table-data-set"])
              ]),
              link: _withCtx(({
              tableDataSet,
              model,
              data,
              original,
              tableRow,
              classes,
              type,
            }) => [
                _createElementVNode("span", null, [
                  (data.value)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: `${data.value}`,
                        target: "_blank"
                      }, _toDisplayString(original[model.field + "_title"] || "Скачать"), 9, _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["table-data-set"])
          ])
        ], 64))
      : (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ScreenSpinner, { key: 2 }))
        : _createCommentVNode("", true)
  ]))
}