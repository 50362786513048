import { ApiService, HttpService } from "table";
import { ApiUserDto } from "@/services/AppUsersService/types";
class ApiAuthService {
  getQueryParams = ApiService.getQueryParams;

  login(data: { login: string; password: string }) {
    return HttpService.post("/api/auth?action=login", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  logout() {
    return HttpService.post("/api/auth?action=logout", {
      headers: {
        "Content-Type": "application/json",
      },
      body: "{}",
    });
  }

  getCurrentUser() {
    return HttpService.get<ApiUserDto[]>(`/api/auth?action=get_current_user`);
  }
}

export default new ApiAuthService();
