export interface SettingsTableAppearanceDto {
  thBackground?: string;
  tdBackground?: string;
  headerColor?: string;
  trColor?: string;
  borderColor?: string;
  linkColor?: string;
  visitedColor?: string;
  selectedHeaderBackground?: string;
  selectedCellBackground1?: string;
  selectedCellBackground2?: string;
  selectedCellBorderColor1?: string;
  selectedCellBorderColor2?: string;
  cellHeight?: string;
  paddingHorizontalCell?: string;
  cellFontSize?: string;
  headerFontSize?: string;
  headerHeight?: string;
}

export function getSettingsTableAppearanceInitDto(): SettingsTableAppearanceDto {
  return {};
}
