import { PanelFiltersFactory, TableDataSet } from "table";
import { PanelFiltersData } from "table/dist/components/PanelFilters/types";
import { FilterLogicalOrComparisonObj } from "table/dist/services/Api/types/ApiFilter";

// применить фильтрацию к таблице
export function applyPanelFiltersData(
  tableDataSet: TableDataSet,
  data: PanelFiltersData,
  additionalFilters: FilterLogicalOrComparisonObj[] = [],
) {
  const filterLogical = PanelFiltersFactory.toFilterLogical(data);
  tableDataSet.setInitialFilters([filterLogical, ...additionalFilters]);
}
