
import { defineComponent, watch } from "vue";
import { DynamicMainMenuItemDto } from "@/services/AppApiService/types";
import DynamicMainMenuItem from "@/modules/main-menu/components/DynamicMainMenuItem.vue";
import {
  boolToString,
  Dictionary,
  isNullOrUndefined,
  ScreenSpinner,
} from "table";
import DynamicComponents from "@/components/smart/DynamicComponents.vue";
import AppApiService from "@/services/AppApiService/AppApiService";
import AppNavLink from "@/components/AppNavLink.vue";

const MAIN_MENU_OPEN_KEY = "dynamic-main-menu-open";

export default defineComponent({
  name: "DynamicMainMenu",
  components: {
    DynamicComponents,
    ScreenSpinner,
    DynamicMainMenuItem,
  },
  props: {
    isOpenHeader: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  setup() {
    return {
      boolToString,
    };
  },
  data() {
    return {
      list: [] as DynamicMainMenuItemDto[],
      open: {} as Dictionary<boolean>,
      show: {
        components: {} as Dictionary<boolean>,
      },
      loading: false,
      isInitMenu: false,
      isMounted: false,
    };
  },
  computed: {
    listColumns(): DynamicMainMenuItemDto[][] {
      const columns: Dictionary<DynamicMainMenuItemDto[]> = {};
      const notCol: DynamicMainMenuItemDto[] = [];
      this.list.forEach((item) => {
        if (isNullOrUndefined(item.col)) {
          notCol.push(item);
          return;
        }

        columns[item.col] ??= [];
        columns[item.col].push(item);
      });
      return [...Object.values(columns), notCol].filter((x) => x.length);
    },
  },
  watch: {},
  async created() {
    this.initOpen();
    await this.loadEntries();
    watch(
      () => this.open,
      () => {
        if (!this.isOpenHeader) {
          this.setOpen();
        }
      },
      { deep: true },
    );
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    initOpen() {
      this.open = this.getOpen();
    },

    async loadEntries() {
      this.loading = true;
      this.list = (await AppApiService.getMainMenu()).json;
      this.loading = false;
    },

    getOpenByItem(item: DynamicMainMenuItemDto) {
      const key = item.caption;
      if (typeof key === "string" && key in this.open) {
        return this.open[key];
      }

      if (item.closed) {
        return false;
      }

      if (this.isOpenHeader) {
        return false;
      }

      return true;
    },

    getOpen(): Dictionary<boolean> {
      if (this.isOpenHeader) {
        return {};
      }

      const openStr = localStorage.getItem(MAIN_MENU_OPEN_KEY);
      if (!openStr) {
        return {};
      }

      return JSON.parse(openStr);
    },

    setOpen() {
      localStorage.setItem(MAIN_MENU_OPEN_KEY, JSON.stringify(this.open));
    },

    onToggleMenu(item: DynamicMainMenuItemDto, value: boolean | undefined = undefined) {
      const currentValue = this.getOpenByItem(item);
      if (this.isOpenHeader) {
        this.open = {};
      }

      this.open[item.caption!] = value ?? !currentValue;
    },

    onClickComponent(component: string) {
      this.onOpenComponent(component);
    },

    onOpenComponent(component: string) {
      this.show.components[component] = true;
    },

    onCloseComponent(component: string) {
      delete this.show.components[component];
    },

    onInitHeaderMenu(item: DynamicMainMenuItemDto | undefined) {
      if (!this.isOpenHeader) {
        return;
      }

      if (!item) {
        return;
      }

      if (this.isInitMenu) {
        return;
      }

      this.onToggleMenu(item, true);
      this.isInitMenu = true;
    },

    onIsActiveChanged(appNavLink: InstanceType<typeof AppNavLink>) {
      this.onInitHeaderMenu(
        appNavLink.mark as DynamicMainMenuItemDto | undefined,
      );
    },
  },
});
