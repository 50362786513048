
import { defineComponent } from "vue";
import { ApiService, ButtonComponent, VOC_NAMES_DICT } from "table";
import { APP_ICONS } from "@/common/consts";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import { VocRepositoryDto } from "table/dist/services/Api/types/VocRepositoryDto";
import EditingVocModal, {
  EditingVocModalMode,
} from "@/modules/editing-voc/components/EditingVocModal.vue";
import AppApiService from "@/services/AppApiService/AppApiService";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";

export default defineComponent({
  name: "CreateVocEntryButton",
  components: {
    ButtonComponent,
    EditingVocExtendedModal,
    EditingVocModal,
  },
  props: {
    voc_type: {
      type: String,
      required: true,
    },
  },
  emits: ["success"],
  setup() {
    return {
      APP_ICONS,
      VOC_NAMES_DICT,
    };
  },
  data() {
    return {
      vocExtraInfo: null as null | VocExtraInfo,
      vocRepositoryDto: null as null | VocRepositoryDto,
      open: {
        vocEntryModal: null as {
          record_id?: number;
          mode: EditingVocModalMode;
        } | null,
      },
    };
  },
  computed: {},
  watch: {},
  async created() {
    this.vocExtraInfo = await AppApiService.getVocExtraInfo(this.voc_type);
    this.vocRepositoryDto = (
      await ApiService.getVocRepository(this.voc_type)
    ).json;
  },
  methods: {},
});
