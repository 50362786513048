import { UserSettingsService } from "@/services/UserSettingsService/UserSettingsService";
import { USER_SETTINGS_KEYS } from "@/services/UserSettingsService/consts";
import {
  getSettingsTableAppearanceInitDto,
  SettingsTableAppearanceDto,
} from "@/modules/settings-appearance/helpers";
import { Logger, TABLE_CLASSES, TableDataSet, TableStylesHelper } from "table";
import { TableEmitColResize } from "table/dist/types/TableEmitColResize";
import {
  TableDataSetListener,
  TableDataSetListenerEvent,
} from "table/dist/classes/TableDataSet";
import { TableSettingsObj } from "table/dist/components/TableComponent/common/services/TableSettingsService/TableSettingsObj";

export interface SaveColSettings {
  width?: string;
}

// методы для работы с настройками пользователя
export const currentSettings = {
  getTableAppearanceDto: async () => {
    return (
      (await UserSettingsService.get<SettingsTableAppearanceDto>(
        USER_SETTINGS_KEYS.TABLE_APPEARANCE,
      )) || getSettingsTableAppearanceInitDto()
    );
  },
  getHints: async (): Promise<boolean> => {
    return (
      (await UserSettingsService.get<boolean>(USER_SETTINGS_KEYS.HINTS)) ?? true
    );
  },
  saveHints: async (value: boolean) => {
    return UserSettingsService.set(USER_SETTINGS_KEYS.HINTS, value);
  },
  getEdit: async (): Promise<boolean> => {
    return (
      (await UserSettingsService.get<boolean>(USER_SETTINGS_KEYS.EDIT)) ?? true
    );
  },
  saveEdit: async (value: boolean) => {
    return UserSettingsService.set(USER_SETTINGS_KEYS.EDIT, value);
  },
  getSizeReportFormBottom: () => {
    return UserSettingsService.get<number>(
      USER_SETTINGS_KEYS.SIZE_REPORT_FORM_BOTTOM,
    );
  },
  saveSizeReportFormBottom: (value: number | undefined) => {
    return UserSettingsService.set(
      USER_SETTINGS_KEYS.SIZE_REPORT_FORM_BOTTOM,
      value,
    );
  },
  getColSettingsKey(id: string) {
    return USER_SETTINGS_KEYS.SAVE_COL_SETTINGS + "_" + id;
  },
  getColSettingsId(tableDataSet: TableDataSet, field: string) {
    // через split по :: сможем получить название колонки в случае чего.
    return tableDataSet.id + "::" + field;
  },
  getColSettings: (id: string) => {
    return UserSettingsService.get<SaveColSettings>(
      currentSettings.getColSettingsKey(id),
    );
  },
  saveColSettings: async (
    id: string,
    value: SaveColSettings | undefined,
    assign?: boolean,
  ) => {
    if (assign && value) {
      const current = await currentSettings.getColSettings(id);
      if (current) {
        value = { ...current, ...value };
      }
    }

    return await UserSettingsService.set(
      currentSettings.getColSettingsKey(id),
      value,
    );
  },
  saveColSize: (tableDataSet: TableDataSet, event: TableEmitColResize) => {
    const id = currentSettings.getColSettingsId(tableDataSet, event.col_name);
    return currentSettings.saveColSettings(id, { width: event.width }, true);
  },
  initColSettings: async (tableDataSet: TableDataSet) => {
    const promisesColSettings = await Promise.all(
      tableDataSet.model.map((tableModel) => {
        return currentSettings
          .getColSettings(
            currentSettings.getColSettingsId(tableDataSet, tableModel.field),
          )
          .then((colSettings) => {
            return {
              tableModel,
              colSettings,
            };
          });
      }),
    );
    for (const { tableModel, colSettings } of promisesColSettings) {
      if (colSettings) {
        if (colSettings.width) {
          tableModel.setWidth(colSettings.width);
        }
      }
    }
  },
  getColResizeListener: (tableDataSet: TableDataSet) => {
    return {
      next: async (arg: TableDataSetListenerEvent<TableEmitColResize>) => {
        if (!arg.saveChanges) {
          return;
        }

        await currentSettings.saveColSize(tableDataSet, arg.event);
      },
    } as TableDataSetListener<TableDataSetListenerEvent<TableEmitColResize>>;
  },
  getTableSettingsObj(key: string) {
    return UserSettingsService.get<TableSettingsObj>(key);
  },
  saveTableSettingsObj: (
    key: string,
    settings: TableSettingsObj | undefined,
  ) => {
    return UserSettingsService.set(key, settings);
  },
};

// применить сохранённые настройки к таблице и настроить слушатели на сохранение
export const useSaveSettingsInTableDataSet = async (
  tableDataSet: TableDataSet,
) => {
  tableDataSet.addOptionsListeners({
    colResize: currentSettings.getColResizeListener(tableDataSet),
  });
  tableDataSet.settingsService.opts.getSettings = async (key) => {
    return currentSettings.getTableSettingsObj(key);
  };
  tableDataSet.settingsService.opts.onSave = async (key, value) => {
    return currentSettings.saveTableSettingsObj(key, value);
  };
  await currentSettings.initColSettings(tableDataSet);
};

const tableStylesHelper = new TableStylesHelper(TABLE_CLASSES);

export async function initUserSettings() {
  try {
    const tableAppearance = await currentSettings.getTableAppearanceDto();
    tableStylesHelper
      .addThBackground(tableAppearance.thBackground)
      .addTdBackground(tableAppearance.tdBackground)
      .addHeaderColor(tableAppearance.headerColor)
      .addTrColor(tableAppearance.trColor)
      .addBorderColor(tableAppearance.borderColor)
      .addSelectedHeaderBackground(tableAppearance.selectedHeaderBackground)
      .addTdSelectedCellBackground(tableAppearance.selectedCellBackground1)
      .addTdSelectedCell2Background(tableAppearance.selectedCellBackground2)
      .addTdSelectedCellBorderColor(tableAppearance.selectedCellBorderColor1)
      .addTdSelectedCell2BorderColor(tableAppearance.selectedCellBorderColor2)
      .addLinkColor(tableAppearance.linkColor)
      .addVisitedColor(tableAppearance.visitedColor)
      .addCellHeight(tableAppearance.cellHeight)
      .addPaddingHorizontal(tableAppearance.paddingHorizontalCell)
      .addCellFontSize(tableAppearance.cellFontSize)
      .addHeaderFontSize(tableAppearance.headerFontSize)
      .addHeaderHeight(tableAppearance.headerHeight)
      .execute();
  } catch (ex) {
    Logger.error("initUserSettings", ex);
  }
}
