
import { defineComponent } from "vue";
import { APP_ICONS, HEADER_IDS } from "@/common/consts";
import {
  ApiHelper,
  ButtonComponent,
  DropdownComponent,
  Logger,
  NotificationsService,
  TABLE_ICONS,
} from "table";
import ApiAuthService from "@/services/ApiAuthService/ApiAuthService";
import { AuthenticationService } from "@/services/AuthenticationService";
import release from "../../release.json";
import AppApiService from "@/services/AppApiService/AppApiService";
import { InfoVersionsDto } from "@/services/AppApiService/types";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";
import DynamicMainMenu from "@/modules/main-menu/DynamicMainMenu.vue";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    DynamicMainMenu,
    ButtonComponent,
    DropdownComponent,
  },
  setup() {
    return {
      HEADER_IDS,
      TABLE_ICONS,
      APP_ICONS,
      AuthenticationService,
      version: release.version,
    };
  },
  data() {
    return {
      show: {
        menu: false,
      },
      infoVersions: null as InfoVersionsDto | null,
    };
  },
  async created() {
    this.infoVersions = await AppApiService.getInfoGetVersions().then(
      (x) => x.json,
    );
  },
  watch: {
    $route() {
      this.show.menu = false;
    },
  },
  computed: {
    versionsTitle(): string {
      if (this.infoVersions === null) {
        return "Загрузка...";
      }

      return `Фронтенд: ${this.version}, Бэкенд: ${this.infoVersions.backend}, База данных: ${this.infoVersions.database}`;
    },
    rightOptions(): DropdownOption[] {
      return [
        {
          title: "Руководство пользователя",
          onClick: this.onOpenUserQuide,
        },
        {
          title: "Настройки",
          onClick: () => {
            this.$router.push("/settings-appearance");
          },
        },
        {
          title: "Выйти",
          onClick: this.onLogout,
        },
      ];
    },
  },
  methods: {
    async onLogout() {
      try {
        await ApiAuthService.logout();
        AuthenticationService.updateIsAuth();
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при выходе",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error(e);
        return;
      }
      NotificationsService.send({
        type: "success",
        title: "Аутентификация",
        text: "Вы вышли из системы",
        duration: 5000,
      });
      await this.$router.push("/login");
    },

    onOpenUserQuide() {
      window.open("/api/info?action=get_user_guide", "_blank");
    },
  },
});
