
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  CircleLoader,
  copy,
  dateFormat,
  Dictionary,
  isNullOrUndefined,
  Logger,
  ModalComponent,
  NotificationsService,
  RepositoryReadModelDto,
  RepositoryReadTypeDto,
  TABLE_ICONS,
  TableCell,
  TDatePicker,
  toJS,
  VocValueStatusEnum,
} from "table";
import EditingVocHistoryVersions from "@/modules/editing-voc/components/EditingVocHistoryVersions.vue";
import { defineComponent, PropType } from "vue";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import { APP_ICONS, HEADER_IDS } from "@/common/consts";
import {
  VocRepositoryDto,
  VocValueDto,
} from "table/dist/services/Api/types/VocRepositoryDto";
import AppApiService from "@/services/AppApiService/AppApiService";
import ApiVocService from "@/modules/editing-voc/services/ApiVocService/ApiVocService";
import { EditingVocModalMode } from "@/modules/editing-voc/components/EditingVocModal.vue";

export default defineComponent({
  name: "EditingVocExtendedModal",
  props: {
    mode: {
      type: String as PropType<EditingVocModalMode>,
      required: true,
    },
    voc_type: {
      type: String,
      required: true,
    },
    record_id: {
      type: [Number, String],
      required: false,
    },
    vocExtraInfo: {
      type: Object as PropType<VocExtraInfo>,
      required: true,
    },
  },
  components: {
    TDatePicker,
    ModalComponent,
    ButtonComponent,
    CircleLoader,
    EditingVocHistoryVersions,
  },
  emits: ["close", "success"],
  setup() {
    return {
      APP_ICONS,
      TABLE_ICONS,
      HEADER_IDS,
      VocValueStatusEnum,
      dateFormat,
    };
  },
  data() {
    return {
      vocRepositoryDto: null! as VocRepositoryDto,
      readOnly: true,
      initialData: {} as Dictionary,
      currentData: {} as Dictionary,
      vocValueDto: null as VocValueDto | null,
      open: {
        historyVersions: false,
      },
      init: true,
    };
  },
  async created() {
    this.vocRepositoryDto = (
      await ApiService.getVocRepository(this.voc_type)
    ).json;
    await this.initRecordId();
  },
  async mounted() {},
  computed: {},
  watch: {
    record_id() {
      this.initRecordId();
    },
  },
  methods: {
    onSelectVersion(vocValueDto: VocValueDto) {
      if (!this.readOnly) {
        this.onCancelEdit();
      }

      this.setVocValueDto(vocValueDto);
    },

    // РЕЖИМ РЕДАКТИРОВАНИЯ ----------------------------------------

    async initRecordId() {
      if (isNullOrUndefined(this.record_id)) {
        this.readOnly = false;
        return;
      }

      this.init = false;
      this.readOnly = true;
      try {
        const vocValueDto = (
          await ApiService.getVocRecord(this.record_id, this.voc_type)
        ).json;
        this.setVocValueDto(vocValueDto);
      } catch (e) {
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при инициализации записи справочника",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
      }
      this.init = true;
    },

    getNewVocValueDto() {
      const newVocValueDto: VocValueDto = toJS(this.vocValueDto!);
      newVocValueDto.attrs = this.initialData;
      if (isNullOrUndefined(newVocValueDto.voc_type)) {
        newVocValueDto.voc_type = this.voc_type;
      }

      return newVocValueDto;
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.currentData[event.model.field] = event.value;
    },

    onCancelEdit() {
      this.currentData = toJS(this.initialData);
      this.readOnly = true;
    },

    onResetField(model: RepositoryReadModelDto) {
      this.currentData[model.field] = this.initialData[model.field];
    },

    async onSaveCurrentVersion(publish = false) {
      if (this.vocValueDto?.record_id === undefined) {
        return;
      }

      this.initialData = toJS(this.currentData);
      const newVocValueDto = this.getNewVocValueDto();
      if (publish) {
        newVocValueDto.value_status = VocValueStatusEnum.Active;
      }

      try {
        const result = (
          await AppApiService.updateVocValueDto(
            this.vocValueDto.record_id,
            newVocValueDto,
          )
        ).json;
        this.setVocValueDto(result);
        this.readOnly = true;
        NotificationsService.send({
          type: "success",
          title: "Статус сохранения",
          text: "Изменения сохранены",
          duration: 5000,
        });
        this.$emit("success");
        this.$emit("close");
      } catch (e) {
        Logger.error({ e });
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Статус сохранения",
          text: errorMessage,
        });
      }
    },

    setVocValueDto(vocValueDto: VocValueDto) {
      const attrs = vocValueDto.attrs || {};
      this.initialData = toJS(attrs);
      this.currentData = toJS(attrs);
      this.vocValueDto = vocValueDto;
    },

    async onSaveNewVersion() {
      this.initialData = toJS(this.currentData);
      const newVocValueDto = this.getNewVocValueDto();
      try {
        const vocValueDto = (
          await AppApiService.saveVocValueDtoNewVersion(newVocValueDto)
        ).json;
        this.setVocValueDto(vocValueDto);
        this.readOnly = true;
        // await this.$router.replace(
        //   `/editing-voc/${vocValueDto.voc_type}/${vocValueDto.record_id}`,
        // );
        this.$emit("success");
        this.$emit("close");
        NotificationsService.send({
          type: "success",
          title: "Статус сохранения",
          text: "Изменения сохранены",
          duration: 5000,
        });
      } catch (e) {
        Logger.error({ e });
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Статус сохранения",
          text: errorMessage,
        });
      }
    },

    onEditCurrentVersion() {
      this.readOnly = false;
    },

    async onArchive() {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      try {
        const newVocValue = (
          await ApiVocService.archive(this.record_id, this.voc_type)
        ).json;
        if (newVocValue) {
          this.setVocValueDto(newVocValue as VocValueDto);
          return;
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При архивировании записи из справочника произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    async onMakeActive() {
      if (isNullOrUndefined(this.record_id)) {
        return;
      }

      try {
        const newVocValue = (
          await ApiVocService.toMakeActive(this.record_id, this.voc_type)
        ).json;
        if (newVocValue) {
          this.setVocValueDto(newVocValue as VocValueDto);
          return;
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title:
            "При попытке сделать запись из справочника активной произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    // РЕЖИМ СОЗДАНИЯ -----------------------------------------------

    getInitAttrs(): Dictionary {
      return this.vocRepositoryDto.form.table.model.reduce((attrs, model) => {
        attrs[model.field] = TableCell.getDefaultValue(this.getTypeAttr(model));
        return attrs;
      }, {} as Dictionary);
    },

    getTypeAttr(
      model: RepositoryReadModelDto,
    ): RepositoryReadTypeDto | undefined {
      return this.vocRepositoryDto.types[model.type || ""];
    },

    async createEntry(publish: boolean) {
      let value_status = VocValueStatusEnum.Draft;
      if (publish) {
        value_status = VocValueStatusEnum.Active;
      }

      const vocValueDto: Omit<
        VocValueDto,
        "record_id" | "valid_from" | "valid_to" | "value_id" | "label"
      > = {
        voc_type: this.voc_type,
        value_status,
        attrs: copy(this.currentData),
      };

      try {
        const result = (await AppApiService.saveVocValueDto(vocValueDto)).json;
        NotificationsService.send({
          type: "success",
          title: "Создание записи в справочнике",
          text: `Запись успешно создана`,
          duration: 5000,
        });
        this.vocValueDto = result;
        this.$emit("success", result);
        this.$emit("close");
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "Создание записи в справочнике",
          text: (await ApiHelper.getErrorMessage(e)) || "Неизвестная ошибка",
        });
        Logger.error({ e });
        return;
      }
    },
  },
});
