import {
  ApiHelper,
  ApiService,
  Dictionary,
  HttpService,
  NotificationsService,
  Subject,
  TDynamicModalService,
} from "table";
import {
  ActionReportForms,
  ActionsProgress,
} from "@/services/ApiActions/types";
import { sleep } from "@/common/helpers/sleep";
import router from "@/router";
import ProgressDynamicModal, {
  ProgressDynamicModalContext,
} from "@/components/smart/ProgressDynamicModal.vue";

export type RefreshType = "record" | "all";

export interface ActionExecRefreshResult {
  caption?: string;
  text?: string;
  refresh?: RefreshType;
  // открывает url в новой вкладке
  url?: string;
  // открывает url в текущей вкладке
  push_url?: string;
  // скачивает результат по указанной ссылке
  download_url?: string;
  // url на компонент, который откроется в модальном окне
  modal?: string;
  // контекст, который передастся в модалку, если она откроется
  context?: any;
}

export type ActionExecJsonResult =
  | { proc_id: string }
  | ActionExecRefreshResult;

export interface ExecuteExecReturn {
  proc_id?: number | string;
  parseProgress?: ActionsProgress;
  result?: ActionExecRefreshResult;
}

class ApiActionsService {
  getQueryParams = ApiService.getQueryParams;

  getListActionsReportForms() {
    return HttpService.get<ActionReportForms[]>(
      `/api/actions?action=list&path=ReportForms`,
    );
  }

  async loadingFile(result: {
    response: Response;
    json: ActionExecRefreshResult;
  }) {
    const filename = result.response.headers
      .get("content-disposition")!
      .split("filename=")[1]
      .split(";")[0];
    const a = document.createElement("a");
    try {
      a.href = URL.createObjectURL(await result.response.blob());
    } catch {
      if (!(result.json && filename)) {
        return;
      }

      let blob = new Blob([JSON.stringify(result.json)], {
        type: "application/json",
      });
      a.href = URL.createObjectURL(blob);
    }
    a.download = filename;
    a.click();
  }

  exec(
    action: Pick<ActionReportForms, "url">,
    context: any,
    addApiPath = false,
  ) {
    return HttpService.post<ActionExecJsonResult>(
      (addApiPath ? `/api` : "") + action.url,
      {
        body: JSON.stringify({ context }),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  getProgress(proc_id: number | string) {
    return HttpService.get<ActionsProgress>(
      `/api/actions?` +
        this.getQueryParams({
          action: "progress",
          proc_id,
        }),
    );
  }

  getResultUrl(proc_id: number | string) {
    return (
      `/api/actions?` +
      this.getQueryParams({
        action: "result",
        proc_id,
      })
    );
  }

  getResult(proc_id: number | string) {
    return HttpService.request<ActionExecRefreshResult>(
      this.getResultUrl(proc_id),
      { mode: "no-cors" },
    );
  }

  parseProgress(
    proc_id: number | string,
    progressSubject?: Subject<ActionsProgress | undefined>,
  ) {
    return (async () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { json: progressResult } = await this.getProgress(proc_id);
        if (progressResult.status !== "active") {
          progressSubject?.next(undefined);
          return progressResult;
        }

        progressSubject?.next(progressResult);
        await sleep(1000);
      }
    })();
  }

  async parseExec(
    result: { response: Response; json: ActionExecJsonResult },
    progressSubject?: Subject<ActionsProgress | undefined>,
    action?: Pick<ActionReportForms, "url" | "caption">,
  ) {
    if (result.json && "proc_id" in result.json) {
      const proc_id = result.json.proc_id;
      TDynamicModalService.show(ProgressDynamicModal, {
        progressSubject,
        onCancelProgress: async () => {
          return await this.cancel(proc_id);
        },
        action,
      } as ProgressDynamicModalContext).then();
      return {
        proc_id,
        parseProgress: await this.parseProgress(proc_id, progressSubject),
      };
    }

    return await this.parseResult({
      response: result.response,
      json: result.json,
    });
  }

  async parseResult(result: {
    response: Response;
    json: ActionExecRefreshResult;
  }) {
    const contentType = result.response.headers.has("content-type")
      ? result.response.headers.get("content-type")
      : undefined;
    if (contentType === "application/json") {
      await this.processReply(result.json);
      return result.json;
    }

    if (contentType === "text/html") {
      const wnd = window.open("about:blank", "", "_blank")!;
      wnd.document.write(await result.response.text());
      return true;
    }

    await this.loadingFile(result);
    return true;
  }

  async executeExec(
    action: Pick<ActionReportForms, "url" | "caption">,
    context: any = undefined,
    addApiPath = false,
  ): Promise<ExecuteExecReturn> {
    const progressSubject = new Subject<ActionsProgress | undefined>();
    const resultExec = await this.exec(action, context, addApiPath);
    const resultParseExec = await this.parseExec(
      resultExec,
      progressSubject,
      action,
    );

    const getResultOrUndefined = (obj: any) => {
      if (!obj || typeof obj !== "object" || "proc_id" in obj) {
        return undefined;
      }

      return obj;
    };

    if (resultParseExec === true) {
      return {
        result: getResultOrUndefined(resultExec),
      };
    } else if ("proc_id" in resultParseExec) {
      const resultParseProgress = await this.parseProgress(
        resultParseExec.proc_id,
        progressSubject,
      );

      if (resultParseProgress.status === "completed") {
        const result = await this.getResult(resultParseExec.proc_id);
        const parseResult = await this.parseResult(result);
        return {
          proc_id: resultParseExec.proc_id,
          result: getResultOrUndefined(parseResult),
        };
      } else if (resultParseProgress.status === "error") {
        const { status_msg } = resultParseProgress;
        NotificationsService.send({
          type: "error",
          title: action.caption,
          text: status_msg,
        });
      }

      return {
        proc_id: resultParseExec.proc_id,
        parseProgress: resultParseProgress,
      };
    } else if (typeof resultParseExec === "object") {
      return { result: resultParseExec };
    } else {
      throw new Error("executeExec not implemented result");
    }
  }

  cancel(proc_id: number | string) {
    return HttpService.get(
      `/api/actions?` +
        this.getQueryParams({
          action: "cancel",
          proc_id,
        }),
    );
  }

  methodExec<T = Dictionary>(path: string, name: string) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/methods?", {
        action: "exec",
        path,
        name,
      }),
    );
  }

  actionList<T = ActionExecRefreshResult[]>(path: string) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "list",
        path,
      }),
    );
  }

  actionExec<T = number>(action_name: string, path: string, context: any) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "exec",
        action_name,
        path,
        context,
      }),
    );
  }

  actionProgress<T = number>(proc_id: string | number) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "progress",
        proc_id,
      }),
    );
  }

  actionCancel<T = number>(proc_id: string | number) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "cancel",
        proc_id,
      }),
    );
  }

  actionResult<T = Dictionary>(proc_id: string | number) {
    return HttpService.get<T>(
      ApiService.getUrl("/api/actions?", {
        action: "result",
        proc_id,
      }),
    );
  }

  async processReply(
    reply: ActionExecRefreshResult | undefined,
  ): Promise<void> {
    if (!reply) {
      return;
    }

    if ("download_url" in reply) {
      await ApiHelper.wrapNotifyError(
        async () => {
          const resultDownload = await HttpService.get(reply.download_url!);
          await this.loadingFile(resultDownload);
        },
        { title: "Ошибка" },
      );
    }

    if (reply.url) {
      window.open(reply.url, "_blank");
    }

    if (reply.push_url) {
      await router.push(reply.push_url);
    }

    const { text, caption } = reply;
    if (text || caption) {
      NotificationsService.send({
        type: "info",
        title: caption,
        html: text,
      });
    }

    if (reply.modal) {
      const result = await TDynamicModalService.show(
        reply.modal,
        reply.context,
      ).then();
      if (typeof result === "object") {
        await this.processReply(result);
      }
    }
  }
}

export default new ApiActionsService();
