import { getCookie } from "@/common/helpers/getCookie";
import { reactive, ref, watch } from "vue";
import { ApiUserDto } from "@/services/AppUsersService/types";
import router from "@/router";
import ApiAuthService from "@/services/ApiAuthService/ApiAuthService";
import { firstOrDefault } from "table";
import { UserSettingsService } from "@/services/UserSettingsService/UserSettingsService";

const AUTH_KEY = "Auth";

class AuthenticationServiceFactory {
  authKey = getCookie(AUTH_KEY);
  user = reactive<{ value: ApiUserDto | null }>({ value: null });
  isAuth = ref(this.getIsAuth());
  private interval: any;

  constructor() {
    // Следим за изменениями авторизации. Если авторизации нет - перенаправляем на логин и чистим юзера.
    watch(
      () => {
        return this.isAuth.value;
      },
      () => this.onIsAuthChange(),
    );
  }

  async startInterval() {
    try {
      await this.updateCurrentUser();
    } finally {
      this.interval = setInterval(async () => {
        await this.updateIsAuth();
      }, 1000);
    }
  }

  stopInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setUser(user: ApiUserDto | null) {
    this.user.value = user;
    this.updateIsAuth();
  }

  async updateCurrentUser() {
    try {
      const userResult = await ApiAuthService.getCurrentUser();
      const user = firstOrDefault(userResult.json);
      this.setUser(user || null);
    } catch {
      this.setUser(null);
    }
  }

  updateIsAuth() {
    const isAuth = this.getIsAuth();
    this.isAuth.value = isAuth;
    if (!this.isAuth) {
      this.setUser(null);
    }
    return isAuth;
  }

  // перед использованием следует обновить isAuth
  async onIsAuthChange() {
    if (router?.currentRoute?.value.path !== "/login" && !this.isAuth.value) {
      await router.push(
        `/login?redirect=${encodeURIComponent(window.location.pathname+window.location.search)}`,
      );
      this.setUser(null);
    } else {
      if (!this.user.value) {
        await this.updateCurrentUser();
      }
    }

    if (this.isAuth.value) {
      await UserSettingsService.updateCurrentLoadedValues();
    }
  }

  private getIsAuth() {
    const auth = getCookie(AUTH_KEY);
    return Boolean(auth);
  }
}

export const AuthenticationService = new AuthenticationServiceFactory();
