
import { defineComponent, PropType } from "vue";
import ActionButton from "@/components/ActionButton/ActionButton.vue";
import { ActionReportForms } from "@/services/ApiActions/types";

export default defineComponent({
  name: "ActionsButtons",
  components: {
    ActionButton,
  },
  props: {
    dataArray: {
      type: Array as PropType<ActionReportForms[]>,
      default: () => [],
    },
  },
  emits: ["click"],
  setup() {
    return {};
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    onClick(event: any) {
      this.$emit("click", event);
    },
  },
});
