import { createApp } from "vue";
import App from "./App.vue";
import { install, Logger } from "table";
import router from "@/router";
import { isDev } from "@/common/helpers/isDev";
import { initUserSettings } from "@/services/UserSettingsService/helpers";
import "@/services/AuthenticationService";
import release from "../release.json";
import AppApiService from "@/services/AppApiService/AppApiService";
import EditingVocModal from "@/modules/editing-voc/components/EditingVocModal.vue";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";
import "@/modules/page/PageInstallExternalOptions";

export const appInstance = createApp(App);
appInstance.component(EditingVocEntry.name, EditingVocEntry);
appInstance.component(EditingVocModal.name, EditingVocModal);
appInstance.component(EditingVocExtendedModal.name, EditingVocExtendedModal);
install(appInstance).use(router).mount("#app");

if (isDev) {
  document.title = `${process.env.NODE_ENV} ` + document.title;
}

Promise.all([initUserSettings(), AppApiService.getInfoGetVersions()])
  .then(([userSettings, versionsResult]) => {
    Logger.debug("end init user settings");
    console.table({ ...release, ...versionsResult.json });
  })
  .catch((e: any) => Logger.error("init user settings exception:", e));

