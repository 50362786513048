import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d29cc61"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"width":"100%","box-sizing":"border-box"}
}
const _hoisted_2 = { class: "dropdown-container action-buttons-context-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonIconComponent = _resolveComponent("ButtonIconComponent")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_ActionButton = _resolveComponent("ActionButton", true)!
  const _component_TooltipComponent = _resolveComponent("TooltipComponent")!

  return (_ctx.isDelimiter)
    ? (_openBlock(), _createElementBlock("hr", _hoisted_1))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_ButtonComponent, {
          ref: "buttonComponentRef",
          class: _normalizeClass(_ctx.data.class || this.class),
          icon: _ctx.data.icon ? { src: _ctx.data.icon } : undefined,
          style: _normalizeStyle([{ whiteSpace: 'nowrap' }, _ctx.style]),
          title: _ctx.data.title,
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick($event, _ctx.data)), ["stop"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.caption) + " ", 1),
            (_ctx.data.actions)
              ? (_openBlock(), _createBlock(_component_ButtonIconComponent, {
                  key: 0,
                  "has-content": Boolean(_ctx.data.caption),
                  icon: _ctx.TABLE_ICONS.chevronUp,
                  "icon-rotate180": !_ctx.open.tooltip
                }, null, 8, ["has-content", "icon", "icon-rotate180"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "icon", "style", "title"]),
        (_ctx.data.actions)
          ? (_openBlock(), _createBlock(_component_TooltipComponent, {
              key: 0,
              ref: "tooltipRef",
              onHide: _ctx.onHide
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.actions, (action, i) => {
                    return (_openBlock(), _createBlock(_component_ActionButton, {
                      key: 
            typeof action === 'string'
              ? action + i
              : action.url + action.caption
          ,
                      class: _normalizeClass(this.childClass),
                      data: action,
                      style: _normalizeStyle(_ctx.style),
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick($event.event, $event.data)))
                    }, null, 8, ["class", "data", "style"]))
                  }), 128))
                ])
              ]),
              _: 1
            }, 8, ["onHide"]))
          : _createCommentVNode("", true)
      ], 64))
}